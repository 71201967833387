import React from "react";
import styled from "styled-components";
import heroImage2 from "../../images/aintrowindframe.png";

const StyledWrapper = styled.section`
  display: flex;
  justify-content: center;
  margin-bottom: 50px !important;
  width: 100%;
  padding: 5rem 0 0;
  .card {
    width: 90%;
    max-width: 1200px;
    border-radius: 1rem;
    box-sizing: border-box;
  }
  .box {
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    width: 65%;
    margin: 0 auto;

    .header {
      font-weight: 600;
    }
    .body {
      font-size: 18px;
      margin: 20px 0;
    }
    @media (max-width: 768px) {
      .header {
        font-size: 25px;
      }
      .body {
        font-size: 18px;
      }
    }
    .button {
      display: flex;
      justify-content: center;
      button {
        border: none;
        color: #3329e0;
        font-weight: 700;
        padding: 12px 35px;
        background: #ccc9ff;
        border-radius: 16px;
        cursor: pointer;
        box-shadow: 0px 5px 5px rgba(75, 93, 104, 0.1);
      }
    }
  }
  @media (max-width: 768px) {
    .box {
      width: 90%;
    }
  }
  @media (min-width: 768.99px) and (max-width: 991px) {
    .box {
      width: 70%;
    }
  }
  @media (min-width: 991.99px) and(max-width: 1440px) {
    .box {
      width: 60%;
    }
  }
   @media (min-width: 1440px) {
    .box {
      width: 60%;
    }
  }
`;

export const CardSection = () => {
  return (
    <StyledWrapper>
      <div className="card card-body border-0 shadow text-center" style={{ marginBottom: "3rem", background: "#e9ecef"}}>
        <div className="box">
          <h3 className="mt-4 mb-3 header"> Windframe is Here! 🚀🚀🚀</h3>
          <h5 className="body">
            Winddframe is an incredibly versatile and powerful software that lets you transcribe and translate audio and video files to text. Apart from that, it features all the video editing tools you need to create professional-looking videos, even if you don’t have experience in video editing. Add subtitles to your videos to make them more accessible for everyone. All tools are accessible online so you don’t need to install any software. Everything works smoothly, straight from your browser!
           </h5>
          <img className="my-5 mx-auto w-75" alt="heroImage" style={{height:"auto"}} src={heroImage2}/>
          <div className="button">
            <a href="/windframe"><button>Build a website!</button></a>
          </div>
        </div>
      </div>
    </StyledWrapper>
  );
};
