import React from 'react';
import styled from 'styled-components';

import hackerNews from '../../images/ahacker.png';
import productHunt from '../../images/aproducth.png';
import webdesignNews from '../../images/awebdesign.png';
import webdesignDepot from '../../images/awebdesignde.png';

export const FeaturedDiv = styled.div`
  margin: auto;
  width: 90%;
  max-width: 1200px;

  h4 {
    text-align: center;
  }
  div {
    .textdiv {
      display: inline-block;
      margin: 10px 1.2rem;
      max-width: 250px;
      img {
        max-width: 250px;
      }
    }
  }

  .imgcont {
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 5rem;
    display: flex;
    allign-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (max-width: 768px) {
      justify-content: center;
    }
  }
`;

export const FeaturedIn = () => {
  return (
    <FeaturedDiv>
      <h4 className="center-block toptext text-3xl font-bold">Featured in</h4>

      <div className="imgcont">
        <p className="textdiv">
          <img src={productHunt} alt="product hunt logo" />
        </p>

        <p className="textdiv">
          <img src={webdesignNews} alt="web designer news logo" />
        </p>

        <p className="textdiv">
          <img src={webdesignDepot} alt="webdesignerdepot logo" />
        </p>

        <p className="textdiv">
          <img src={hackerNews} alt="hacker news logo" />
        </p>
      </div>
    </FeaturedDiv>
  );
};
