import React from 'react';
import styled from 'styled-components';

import '../../../styles/scss/style.scss'

const Container = styled.div`
  padding: 3rem;
  border-radius: 2rem;
  background-color: #f7f7f8;
  width: 90%;
  margin: 0 auto;
  max-width: 1200px;
`;

const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 2rem;
`;

const Text = styled.div`
  display: flex;
  justify-content: space-between;

  @media(max-width: 768px) {
    flex-direction: column;
  }
`;

const Paragraph = styled.p`
  font-size: 1rem;
  width: 48%;

  @media(max-width: 768px) {
    width: 100%;
  }
`;

export const Hero2 = () => {

  return (
    <Container>
      <Title>Automatic Transcriptions Online</Title>
      <Text>
        <Paragraph>VEED’s online transcription service allows you to transcribe your audio and video files to text in just one click. It is much more versatile compared to other services that only allow you to transcribe either an audio or a video file. With VEED, you can do both. Simply upload your audio or video, click on ‘Auto Transcribe’ and download your transcript! Our transcription tool supports all popular audio formats including MP3, WAV, and all video file types like MP4, MOV, AVI, and more. It is incredibly easy to use and works straight from your browser. You don’t need to download any software. </Paragraph>
        <Paragraph>Our auto transcription service has a 95% accuracy—which is a lot higher compared to other services that are even more expensive. You only need to edit a few words or phrases. It takes minutes compared to hours of manually transcribing files! With a VEED Pro account, you will have access to unlimited transcription downloads, starting at only $24/month, billed annually. You can visit our pricing page for more information. No need to pay for expensive services like Rev!</Paragraph>
      </Text>
    </Container>
  );
};
