import React from 'react';
import { Link } from 'gatsby';

import '../../../styles/scss/style.scss'

export const Cta = () => {

  return (    
      <div className="text-light py-5" style={{ background: 'rgba(26,32,44,1)' }}>
        <div className="container">
          <div className="hero mt-5">
            <p className="text-center mb-1">
              Create or prototype a website using a drag and drop builder
            </p>
            <h1 className="text-center mb-5 mx-auto" style={{ maxWidth: '720px' }}>
              Start building your website&nbsp;
            </h1>

            <div className="hero__buttons text-center mt-5">
              <Link href="https://windframe.devwares.com" className="btn1 hero__btn btn__inverse">
                <span className="btn__text">Create a website</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
  );
};

