import React from 'react';
import { Link } from 'gatsby';
import '../../../styles/scss/style.scss'
import image from '../../images/aheadwind.png';
import image3 from '../../images/aheadfra2.png';
import image2 from '../../images/aheadwind2.png';

export const Preview = () => {
  return (
    <div className="container">
      <div className="feature">
        <h2 className="text-center mb-5">
          Quick steps on how to use windframe
        </h2>
        <div className="feature__features">
          <div className="feature_wrapper">
            <img src={image}/>
            <h4>Built with tailwindcss</h4>
            <p>
              Windframe was built with tailwinds, allowing you to edit using tailwinds classes and
            </p>
          </div>
          <div className="feature_wrapper">
            <img src={image2}/>
            <h4>Export your designs</h4>
            <p>
              Export the designs you have created on windframe into well written and understandable code
            </p>
          </div>
          <div className="feature_wrapper">
            <img src={image3}/>
            <h4> Create multiple websites and prototypes </h4>
            <p>
              With windframe you can create multiple websites prototypes and compare them over time.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
