import React from 'react';

import '../../../styles/scss/style.scss'

export const Hero = () => {

  return (
    <div className="container">
      <div className="feature feature-2">
        <div className="feature__wrap">
          <div className="feature__left" style={{marginBottom: '8rem'}}>
            <h2 className="feature__lead feature__lead--1 showcase__lead">
              Transcription
            </h2>
            <p className="feature__text mb-5">
              Transcribe audio and video to text online. Automatic transcription in just one click
            </p>
            <a target="_blank" rel="noreferrer" href="https://windframe.devwares.com" className="btn1 btn__inverse">
              Preview Components
            </a>
          </div>
          <div className="feature__right">
            <img
              src={require('../../../components/images/awindframe31.png')}
              alt="#"
              className="feature__img"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
