export const questions = [
  {
    id: 1,
    question: 'Can I use the source code generated from windframe elsewhere',
    answer: 'Yes any code exported from the editor can be integrated in any project of your choice or hosted on any hosting service your choice',
  },
  {
    id: 2,
    question: 'What is the format of the source code exported from windframe',
    answer: 'The code exported from windframe is a Html file, this makes it easier to integrate with almost any framework of your choice',
  },
  {
    id: 3,
    question: 'Can I use windframe to build websites for different clients',
    answer: 'Of course, Windframe supports multiple projects which makes it possible to work on different client projects at once',
  },
  {
    id: 4,
    question: 'What version of Tailwind CSS does windframe use',
    answer: 'Windframe currently ships with the latest version of Tailwind CSS which is v3. It will also be updated as updates are made to the core Tailwind CSS library',
  },
  {
    id: 5,
    question: 'Where can I get support',
    answer: 'Whether night or day please do feel free to reach out to us on contact@devwares.com for any inquiries or issues you might have. We are more than happy to help.',
  },

]
