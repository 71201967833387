import React from 'react';
import '../../../styles/scss/style.scss'

export const Hero4 = () => {

  return (
    <div className="container">
      <div className="feature feature-2">
        <div className="feature__wrap">
          <div className="feature__left">
            <img
              src={require('../../../components/images/designer.png')}
              alt="#"
              className="feature__img"
            />
          </div>
          <div className="feature__right">
            <h2 className="feature__lead feature__lead--1 showcase__lead">
               Built for the modern designer.
            </h2>
            <p className="feature__text mb-5">
              Windframe was also built to be designer friendly. A lot of the design decisions were made by looking at some of the most popular design tools (figma and photoshop). 
              This was to make the design experience more natural. As a designer using windframe you will be able to deliver well writren code for the designs you created which can be used anywhere.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
