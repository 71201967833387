import React from 'react';

import '../../../styles/scss/style.scss'

export const Hero5 = () => {

  return (
    <div className="container">
      <div className="feature feature-2">
        <div className="feature__wrap">
          <div className="feature__left">
            <h2 className="feature__lead feature__lead--1 showcase__lead">
              Built with web developers in mind
            </h2>
            <p className="feature__text mb-5">
              Windframe was built with web developers in mind. Website builders are usually not known to be developer friendly 
              but we have created windframe all while thinking about increasing the developers productivity instead of limiting them.
               Easily edit any element using tailwind css classes that you are familiar with. Use the layers tab to see how your components stack together
              <br /><br />
              <strong>As a backend, fullstack or frontend developer</strong> you can easily use windframe to prototype any project and export your code
               when done. 
            </p>
          </div>
          <div className="feature__right">
            <img
              src={require('../../../components/images/developer.png')}
              alt="#"
              className="feature__img"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
