import React from 'react';
import Helmet from 'react-helmet';

import NavBar from "../components/NavBar/NavBar";
import FAQ from '../components/NewPage/React/Faq';
import { Footer } from "../components/Footer/Footer";
import { Hero } from '../components/NewPage/React/Hero';
import { Hero2 } from '../components/NewPage/React/Hero2';
import { Hero3 } from '../components/NewPage/React/Hero3';
import { Hero4 } from '../components/NewPage/React/Hero4';
import { Hero5 } from '../components/NewPage/React/Hero5';
import { Preview } from '../components/NewPage/React/Preview';
import { Cta } from '../components/NewPage/React/Cta';
import AppContext, { UserContext } from "../components/AppContext";
import { FeaturedIn } from '../components/NewPage/React/FeaturedIn';
import { CardSection } from '../components/NewPage/React/CardSection';

const NewPage = () => {

  return (
    <AppContext>
      <Helmet>
        <title>NewPage - Devwares</title>
        <meta
          name="title"
          content="NewPage - Devwares"
        />
        <meta
          name="description"
          content="Get high quality web products at a discounted price"
        />
        <meta
          property="og:title"
          content="NewPage - Devwares"
        />

        <meta
          property="og:description"
          content="Get high quality web products at a discounted price"
        />
        <meta
          property="twitter:title"
          content="NewPage - Devwares"
        />
        <meta
          property="twitter:description"
          content="Get high quality web products at a discounted price"
        />
        <meta property="og:site_name" content="Devwares" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@devwares" />
        <meta name="twitter:creator" content="@devwares" />
      </Helmet>
      <UserContext.Consumer>
        {({ user }) => (
          <div>
            <div className="container">
              <NavBar user={user}/>
            </div>
            <Hero/>
            <FeaturedIn/>
            <Hero2/>
            <Preview/>
            <Hero3/>
            <Hero4/>
            <Hero5/>
            <FAQ/>
            <Cta/>
            <CardSection/>
            <div className="container">
              <Footer />
            </div>
				  </div>
			  )}
      </UserContext.Consumer>
    </AppContext>
  );
};

export default NewPage;
